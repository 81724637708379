import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Button, Label, Row, Col } from "reactstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomDropdown2 from "../../../components/common/CustomDropDown2";
import { FaInfo } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { handleNextPreviousClick } from "../Helpers/pathCalculations/checkPathCalculation";

const ValidationSchema = Yup.object().shape({
  from: Yup.string().required("This field is required."),
  to: Yup.string().required("This field is required.")

});

const TraversableSideBar = ({
  id,
  floorID,
  setAddNew,
  addNew,
  selFloorPlanDtls,
  options,
  setSelTraversibleDetails,
  selTraversibleDetails,
  toggleVertical,
  switchFloor,
  graph,
  verticalTransports,
  setCommonSidebarVisible,
  showPath,
  allVerticalTransports,
  handleEndDirectionclick,
  canvas,
  setSelectedPaths,
  generateAutoConnections
}) => {

  const [mapDivSize, setMapDivSize] = useState(window.innerHeight - 70);

  const addBeaconClick = () => {
    if (floorID) {
      setAddNew(true);
    } else {
      toast.warning("Please select a floor plan to add traversable");
    }
  };

  const goBack = () => {
    setAddNew(false)
    setCommonSidebarVisible(true)
  };

  const handleResize = () => {
    const { clientHeight } = window.document.getElementById("pageDiv");
    setMapDivSize(window.innerHeight - 70);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);

  const findPath = async () => {
    canvas.current.discardActiveObject();
    setSelectedPaths(false);
    if (selTraversibleDetails?.from && selTraversibleDetails?.to) {

      if (selTraversibleDetails?.from == selTraversibleDetails?.to) {
        toast.warning('Please choose a different source or destination to find the way.');

      } else {
        if (selTraversibleDetails?.from_floor_id == selTraversibleDetails?.to_floor_id && selTraversibleDetails?.from_floor_id == selFloorPlanDtls?.enc_id) {
          showPath(selTraversibleDetails?.from, selTraversibleDetails?.to)
        } else if (selTraversibleDetails?.from_floor_id == selFloorPlanDtls?.enc_id) {
          if (verticalTransports?.length > 0) {
            toggleVertical()
          } else {
            toast.warning('Please add a vertical transport option to help you find your way to your destination.');
          }
        } else if (selTraversibleDetails?.from_floor_id != selFloorPlanDtls?.enc_id && selTraversibleDetails?.from_floor_id == selTraversibleDetails?.to_floor_id) {
          const returnValue = await switchFloor(selTraversibleDetails?.from_floor_id)
          setTimeout(() => {
            showPath(selTraversibleDetails?.from, selTraversibleDetails?.to)
          }, 1000);
        }
        else {
          console.log('here')
          const returnValue = await switchFloor(selTraversibleDetails?.from_floor_id)
          if (returnValue) {
            setTimeout(() => {
              toggleVertical()
            }, 500);
          }
        }
      }
    }
  }

  return (
    <div
      className="bar"
      id="inner-customizer2"
      style={{
        position: "relative",
        height: mapDivSize,
        paddingBottom: "20px"
      }}
    >
      <Row className="backRow">
        <Col md={8}>
          <h1>Navigation Path </h1>
        </Col>
        {/* {addNew && ( */}
        <Col md={4}>
          <div className="backArrow float-right">
            <BsArrowLeftShort onClick={goBack} />
          </div>
        </Col>
        {/* )} */}
      </Row>
      <Formik
        initialValues={{
          from: "",
          to: "",
          ...selTraversibleDetails
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, setFieldError) => {
          // console.log(values, "values");
        }}
        enableReinitialize
      >
        {({
          errors,
          values,
          touched,
          handleSubmit,
          handleChange,
          setFieldValue,
          setFieldError
        }) => (
          <form
            className="av-tooltip tooltip-label-bottom formGroups"
            onSubmit={(e) => handleSubmit(e, setFieldError)}
          >
            <div
              className="custom-scrollbar customScroll"
              style={{ height: mapDivSize }}
            >
              {addNew ? (
                <div className="bar-sub">
                  <div>
                    <div className="bar-sub-header" style={{ marginTop: 0 }}>
                      <p style={{ marginTop: 0 }}>Navigation Path Test</p>
                    </div>
                    <div className="pl-4 pr-4">
                      <div className="marginBottom">
                        <Label className="form-labels">From</Label>
                        <CustomDropdown2
                          name="from"
                          options={options}
                          setFieldValue={setFieldValue}
                          values={selTraversibleDetails}
                          setCustomerValues={{}}
                          selectValue={{}}
                          onChange={(e) =>
                            setSelTraversibleDetails((prev) => ({
                              ...(prev && prev),
                              from: `${e.name}_${e.enc_id}`,
                              from_floor_id: e?.floorplan_id,
                              is_miltiple: false,
                              isNext: 0
                            }))
                          }
                        />
                        {errors.from && touched.from ? (
                          <div className="text-danger mt-1">
                            {errors.from}
                          </div>
                        ) : null}
                      </div>

                      <div className="marginBottom">
                        <Label className="form-labels">To</Label>
                        <CustomDropdown2
                          name="to"
                          options={options}
                          setFieldValue={setFieldValue}
                          values={selTraversibleDetails}
                          setCustomerValues={{}}
                          selectValue={{}}
                          onChange={(e) =>
                            setSelTraversibleDetails((prev) => ({
                              ...(prev && prev),
                              to: `${e.name}_${e.enc_id}`,
                              to_floor_id: e?.floorplan_id,
                              is_miltiple: false,
                              isNext: 0
                            }))
                          }
                        />
                        {errors.to && touched.to ? (
                          <div className="text-danger mt-1">
                            {errors.to}
                          </div>
                        ) : null}
                      </div>
                      {!selTraversibleDetails?.is_miltiple ? (
                        <Button
                          className="btn-primary bar-btn float-right mt-1 mb-3"
                          type="primary"
                          size="medium"
                          id="beaconSubmitBtn"
                          onClick={findPath}
                        >
                          Find
                        </Button>
                      ) : (
                        <div className="d-flex float-right">
                          {selTraversibleDetails?.from_floor_id != selFloorPlanDtls?.enc_id &&
                            <Button
                              className="btn-primary bar-btn  mt-1 mb-3 mr-3"
                              type="primary"
                              size="medium"
                              onClick={() => {
                                handleNextPreviousClick(selTraversibleDetails?.isNext, 'previous', selTraversibleDetails, switchFloor, showPath, setSelTraversibleDetails)
                              }}
                              disabled={selTraversibleDetails?.from_floor_id == selFloorPlanDtls?.enc_id}
                            >
                              Previous
                            </Button>
                          }
                          {(selTraversibleDetails?.to_floor_id != selFloorPlanDtls?.enc_id) ? (
                            <Button
                              className="btn-primary bar-btn  mt-1 mb-3"
                              type="primary"
                              size="medium"
                              onClick={async () => {
                                handleNextPreviousClick((selTraversibleDetails?.isNext ?? 0), 'next', selTraversibleDetails, switchFloor, showPath, setSelTraversibleDetails)

                              }}
                            >
                              Next
                            </Button>
                          ) : (
                            <Button
                              className="btn-danger btn-danger-btn bar-btn  mt-1 mb-3"
                              type="primary"
                              size="medium"
                              onClick={() => {
                                handleEndDirectionclick()
                              }}
                            >
                              End direction
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                    <div style={{ marginTop: "4rem" }}>
                      <div className="warning-pin-div">
                        <div className="d-flex align-items-center justify-content-center mb-2">
                          <div className="info-cont">
                            <FaInfo />
                          </div>
                        </div>
                        <div className=" text-center  ">
                          <p className="label color-labels">
                            Draw a path that connects along all walkable routes;
                            Connect all the pins to the path to enable
                            navigation.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="btn-wrpr">
                      <Button
                        className="btnCancel "
                        type="button"
                        size="medium"
                        hidden
                        onClick={() => {
                          setAddNew(false);
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        className="btn-primary bar-btn"
                        htmlType="submit"
                        type="primary"
                        size="medium"
                        id="beaconSubmitBtn"
                        hidden
                      >
                        Submit
                      </Button>
                      {/* <Button
                        className="btn-primary bar-btn float-right"
                        // type="primary"
                        // size="medium"
                        // hidden
                        onClick={generateAutoConnections}
                      >
                        Generate
                      </Button> */}

                    </div>
                  </div>

                </div>
              ) : (
                <>
                  <div className="bar-sub-header" style={{ marginTop: 0 }}>
                    <p style={{ marginTop: 0 }}>Add New Navigation path</p>
                    <div className='plus-icon' onClick={() => addBeaconClick()}>
                      <GoPlus />
                    </div>
                  </div>
                </>
              )}
              {/* <Label for="exampleEmail1" className="form-labels">Name</Label> */}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default TraversableSideBar;
