import { Field, Formik } from 'formik'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Button, Label, Row, Col, Modal, ModalBody, ModalHeader, Card, CardBody, Spinner } from 'reactstrap'
import { BsArrowLeftShort } from 'react-icons/bs';
import { BiSolidPencil } from 'react-icons/bi'
import { FaInfo } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io'
import ProdSpecItem from './ProdSpecItem'
import { ProductSvg } from "../CustomSvg";
import TagInputComp from '../../../components/tagInput/TagInputComp';
import { postRequest, getRequest, deleteRequest } from '../../../hooks/axiosClient';
import { getCurrentUser } from '../../../helpers/utils';
import { SetBackEndErrorsAPi } from '../../../hooks/setBEerror';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { environmentaldatas } from '../../../constant/defaultValues';
import ImageUploader from '../../../components/constants/imageCropNew';
import AutosaveForm from './AutoSaveForm';
import { FiSearch } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import ColorPicker from '../../../components/common/Colorpicker';
import PaymentForm from '../../../components/stripe/payment';
import { PlanExpiryDetails, deletePinApi, revertPackage } from '../Helpers/apis/otherApis';
import { handleBlockEnter } from '../Helpers/constants/constant';
import { ProPinModal } from '../Helpers/modal/proPinModal';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';
import DragPinImage from '../../../assets/icons/deleteIcon.png'
import { getProductPin, getProductPinDragPreview } from '../Helpers/getPinIcons';
import BulkUploadPin from '../Helpers/modal/BulkUploadModal';
import { removeFabricObjectsEncId } from '../Helpers/bringFabricObjects';


const { image_url } = environmentaldatas;

const ValidationSchema = Yup.object().shape({
    // product_name: Yup.string().required('This field is required.'),
    // product_code: Yup.string().required('This field is required.'),
})

const ProductSideBar = ({
    selProductDtls,
    setSelProductDtls,
    selFloorPlanDtls,
    id, floorID,
    projectSettings,
    addNew, setAddNew,
    getProductList,
    images,
    setImages,
    specifications,
    setSpecifications,
    onSideBarIconClick,
    activeTab,
    savingTimer, setSavingTimer,
    handleEnableDisable,
    totalPinsUsed,
    setFloorID,
    productList,
    getFloorPlanByid,
    searchTerm,
    setSearchTerm,
    setCommonSidebarVisible,
    dropDownFloor,
    setIsDirty,
    isDirty,
    setPanTool,
    stopPathDrawing,
    canvas,
    onEditProduct
}) => {

    const initialValues = {
        product_name: 'New product',
        // product_code: products?.length + 1,
        product_code: null,
        tags: selProductDtls?.tags ?? projectSettings?.product_tags ?? [],
        description: '',
        website: '',
        specifications: [],
        boundary_color: null,
        boundary_attributes: null,
        product_color: null,
        enc_id: null,
        position: null,
        // position: { x: 0, y: 0 },
        ...selProductDtls
    }

    const imgInputRef = useRef()
    const fileInputRef = useRef()
    const [mapDivSize, setMapDivSize] = useState(window.innerHeight - 80)

    const [deletedImages, setDeletedImages] = useState([]);
    const [imagesForpost, setImagesForpost] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [blobImage, setBlobImage] = useState(null);
    const [postCall, setPostCall] = useState(false);
    const [modal, setModal] = useState(false);
    const toggle2 = () => setModal(!modal);
    const [fileKey, setFileKey] = useState(Date.now());
    const [planDetails, setPlanDetails] = useState();
    const [modalPlan, setModalPlan] = useState(false);
    const [modalBulk, setModalBulk] = useState(false);
    const toggle3 = () => setModalPlan(!modalPlan);
    const [stripeModal, setStripeModal] = useState(false);
    const toggleStripe = () => setStripeModal(!stripeModal);
    const [backClick, setBackClick] = useState(false);
    const [color, setColor] = useState(null);
    const [openPicker, setOpenPicker] = useState(null);

    const addProductClick = () => {
        if (totalPinsUsed?.used_products == totalPinsUsed?.total_products) {
            PlanExpiryDetails(id, setPlanDetails, setModalPlan);
        } else if (totalPinsUsed?.used_products == totalPinsUsed?.total_products) {
            PlanExpiryDetails(id, setPlanDetails, setModalPlan);
        } else if (totalPinsUsed?.used_products == totalPinsUsed?.total_products) {
            PlanExpiryDetails(id, setPlanDetails, setModalPlan);
        } else {
            addClick();
            document.getElementById("productSubmitBtn")?.click();
        }
    }

    const planCheck = () => {
        if (totalPinsUsed?.used_products == totalPinsUsed?.total_products) {
            PlanExpiryDetails(id, setPlanDetails, setModalPlan);
            setTimeout(() => {
                removeFabricObjectsEncId(canvas, selProductDtls?.enc_id, 'product')
            }, 2000);
            setSavingTimer(false)
            return
        } else if (totalPinsUsed?.used_products == totalPinsUsed?.total_products) {
            PlanExpiryDetails(id, setPlanDetails, setModalPlan);
            setTimeout(() => {
                removeFabricObjectsEncId(canvas, selProductDtls?.enc_id, 'product')
            }, 2000);
            setSavingTimer(false)

        } else if (totalPinsUsed?.used_products == totalPinsUsed?.total_products) {
            PlanExpiryDetails(id, setPlanDetails, setModalPlan);
            setTimeout(() => {
                removeFabricObjectsEncId(canvas, selProductDtls?.enc_id, 'product')
            }, 2000);
            setSavingTimer(false)
            return
        } else {
            document.getElementById("productSubmitBtn")?.click();
            setSelProductDtls((prev) => ({
                ...prev,
                isDrop: false
            }));
        }
    }

    const addClick = () => {
        setPanTool(false)
        if (floorID) {
            // setAddNew(true)
            setSelProductDtls()
            setImagesForpost([])
            setImages([])
            setDeletedImages([])
            setSpecifications([])
            setCroppedImage(null)

        } else {
            toast.warning('Please select a floor plan to add a product')
        }
    }

    const bulkUploadClick = () => {
        if (floorID) {
            setModalBulk(true)
        } else {
            toast.warning('Please select a floor plan to bulk upload.')
        }
    };

    useEffect(() => {
        console.log(floorID, 'onSideBarIconClick')
        // if (floorID) {
        getProductList(floorID)
        // }
    }, [floorID])

    const addProduct = async (values, setFieldError) => {
        console.log(values, 'addProduct')
        let floor_id
        setFloorID((prev) => {
            floor_id = prev;
            return prev;
        });
        if (values?.enc_id && values?.isDrop) {
            // check plan after bulk upload and drop pin
            planCheck()
            return
        }
        setSavingTimer(true);
        let uniqueImages = imagesForpost.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        for (let image of uniqueImages) {
            if (croppedImage) {
                image = image;
            } else {
                image = null;
            }
        }


        const filteredspecifications = specifications.filter((obj) => {
            return Object.values(obj).some((val) => val !== null && val !== undefined);
        });
        // if (values.position) {
        let value = {
            customer_id: projectSettings?.enc_customer_id ?? getCurrentUser()?.user?.common_id,
            product_name: values?.product_name ?? "New product",
            project_id: id,
            tags: values?.tags,
            // floor_plan_id: selFloorPlanDtls?.enc_id,
            floor_plan_id: values?.position === null ? null : (values?.enc_floor_plan_id ?? floor_id ?? floorID ?? selFloorPlanDtls?.enc_id),
            // image_path: uniqueImages?.length == 0 ? null : uniqueImages?.map(el => el),
            // deleted_images: deletedImages,
            product_code: values?.product_code,
            description: values?.description,
            specifications: JSON.stringify(filteredspecifications),
            website: values?.website,
            product_color: values?.product_color ?? projectSettings?.product_color,
            boundary_color: values?.boundary_color,
            positions: values?.position ?? null
        }
        if (values?.enc_id) {
            value.id = values?.enc_id
            value.is_published = '0';
            value.discard = '1';
            value.publish = '1';

        }
        try {
            const reqUrl = `product`
            const response = await postRequest(reqUrl, value);
            const data = response.response?.data ?? [];
            if (response.type === 1) {
                setImagesForpost([])
                setCroppedImage()
                setDeletedImages([])
                if (values?.enc_id && isDirty) {
                    setSelProductDtls((prev) => ({ ...prev, ...values, enc_id: data?.id }));
                } else {
                    setSelProductDtls();
                }
                getProductList(floorID)
                handleEnableDisable();
                setIsDirty(false)
                setTimeout(() => {
                    setSavingTimer(false)
                }, 1000);

                if (backClick) {
                    onSideBarIconClick(activeTab);
                    setBackClick(false)
                }

            } else {
                setSavingTimer(false)
                SetBackEndErrorsAPi(response, setFieldError);
            }
        } catch (error) {
            setSavingTimer(false)
        }

        // } else {
        //     toast.error('Click on map to add pin');
        // }
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = productList.filter((val) => {
        const {
            product_name = '',
            floor_plan = '',
            search_name

        } = val;
        if (searchTerm === '') {
            return val;
        }
        return (
            product_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            floor_plan?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            search_name?.toLowerCase().includes(searchTerm.toLowerCase())

        );
    });

    const removeProduct = (index, row) => {
        deleteClick(row)

        let floor_id
        setFloorID((prev) => {
            floor_id = prev;
            return prev;
        });
    }

    const deleteClick = async (row) => {
        swal({
            title: "Are you sure you want to delete?",
            text: "This action is permanent and cannot be undone.",
            icon: "warning",
            buttons: [
                {
                    text: "No",
                    value: "No",
                    visible: true,
                    className: "btn-danger",
                    closeModal: true,
                },
                {
                    text: "Yes",
                    value: "Yes",
                    visible: true,
                    className: "btn-success",
                    closeModal: true,
                },
            ],
        })
            .then((value) => {
                switch (value) {
                    case "Yes":
                        deletePinApi(`product/${row?.enc_id}`, setFloorID, floorID, getProductList, handleEnableDisable, projectSettings)
                        break;
                    default:
                        break;
                }
            });

    }

    const onSelectImg = async (e) => {
        const file = e?.target?.files[0];
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

        if (file && !allowedTypes.includes(file.type)) {
            toast.warning('Sorry! Only JPEG, PNG, and JPG files are allowed for upload.');
            return;
        }
        setFileKey(Date.now());
        // setFieldValue(e)
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewImage(reader.result);
            //   onReset()
            setModal(true);

        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const handleDeleteImage = (image, index, setFieldValue) => {
        images.splice(index, 1);
        setImages([...images])
        const trimmedImageUrl = image.replace(image_url, '');
        setDeletedImages([...deletedImages, trimmedImageUrl]);
        setFieldValue('image_path', images);
        postImages([trimmedImageUrl], 'delete')
    }

    const editClick = (product) => {
        setPanTool(false)
        if (product?.position) {
            getFloorPlanByid(product?.fp_id, 'products', "0", "default", product);
        } else {
            onEditProduct(product)
        }
    }

    const ProductItem = ({ product, index, }) => {
        // const canDrag = product?.position?.x === 0 && product?.position?.y === 0
        const canDrag = (product?.position === null) ;
        const id = product.enc_id;
        const [{ isDragging }, drag, preview] = useDrag({
            type: 'productpin',
            item: () => {
                return { index, id, product };
            },
            canDrag: () => {
                // Block dragging if the position is not (0, 0)
                return canDrag && floorID;
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        return (
            <div className='drag-wrpr mxx-3'  >
                {/* <DragPreviewImage connect={preview} src={getProductPinDragPreview(projectSettings?.product_color)} /> */}
                <div className={`drag-item ${canDrag && 'can-drag'}`} ref={drag}>
                    <div >
                        <ProductSvg color={product?.product_color ?? "#6A6D73"} />
                    </div>
                    <div>
                        <p>{product.product_name}{product?.floor_plan && ` (${product.floor_plan})`}</p>
                    </div>
                    <div className='flex-grow-1' />
                    <div className=' edit-square magical-words' onClick={() => editClick(product)}  >
                        <BiSolidPencil fontSize={15} />

                    </div>
                </div>

                <div className='ml-2  rounded-circle' onClick={() => removeProduct(index, product)} style={{ backgroundColor: '#E5E5E5', cursor: 'pointer', marginBottom: '8px', padding: '4px' }} >
                    <IoMdClose fontSize={10} />

                </div>
            </div>
        )
    }

    const renderProductItem = useCallback((product, index) => {
        return (
            <ProductItem
                key={product.id}
                index={index}
                id={product.id}
                product={product}
            />
        )
    }, [])

    useEffect(() => {
        if (croppedImage) {
            setImages([...images, croppedImage]);
            postImages([blobImage], 'post')
            // setImagesForpost([...imagesForpost, croppedImage])

        }
    }, [croppedImage]);

    const postImages = async (imagePath, type) => {
        const formData = new FormData();
        formData.append('id', selProductDtls?.enc_id);
        formData.append('is_published', '0');
        formData.append('discard', '1');
        formData.append('publish', '1');

        if (type === 'post') {
            if (imagePath) {
                // Append each image to the FormData
                // imagePath.forEach((image, index) => {
                formData.append(`image_path[${0}]`, imagePath[0]);
                // });
            }
        } else {
            if (imagePath) {
                // Append each deleted image to the FormData
                imagePath.forEach((deletedImage, index) => {
                    formData.append(`deleted_images[${index}]`, deletedImage);
                });
            }
        }

        try {
            const reqUrl = `product-image`
            const response = await postRequest(reqUrl, formData, true);
            const data = response.response?.data ?? [];
            if (response.type === 1) {
                setImagesForpost([])
                const images = data?.image_path?.map((item) => image_url + item)
                setImages(images)
                handleEnableDisable()
                setCroppedImage()
                setBlobImage()
                setDeletedImages([]);
                setModal(false);
            } else {
                SetBackEndErrorsAPi(response, setFieldError);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const goBack = () => {
        setSearchTerm('');
        if (addNew) {
            console.log(isDirty, 'isDirty')
            // if (selProductDtls?.position && isDirty) {
            if (isDirty) {
                setBackClick(true)
                document.getElementById("productSubmitBtn")?.click();
                // onSideBarIconClick(activeTab);
            } else {
                setAddNew(false)
                setSelProductDtls()
                stopPathDrawing()
                // resetCanvasTransform()
            }
        } else {
            setCommonSidebarVisible(true)

        }
        // onSideBarIconClick(activeTab)
    }

    const handleResize = () => {
        const { clientHeight } = window.document.getElementById('pageDiv')
        setMapDivSize(window.innerHeight - 80)
    }

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const handleAutoSave = (type) => {
        setTimeout(() => {
            console.log(type, 'type')
            document.getElementById("productSubmitBtn")?.click();
        }, 500)
    }

    const addCardDetails = (planDetails) => {
        toggle3()
        setStripeModal(true);
    }

    const handlePickerClick = (name) => {
        setOpenPicker(name);
    };

    return (
        <div className="bar" id="inner-customizer2" style={{ position: 'relative', height: mapDivSize, paddingBottom: '20px' }} >
            <Row className='backRow'>
                <Col md={8}>
                    <h1> {addNew ? 'Product Pin Details' : 'Product Pins'}</h1>
                </Col>
                <Col md={4} >
                    <div className='backArrow float-right' style={(savingTimer && !isDirty) ? { pointerEvents: 'none' } : { opacity: '1' }} onClick={goBack}>
                        {(savingTimer && !isDirty && addNew) ?
                            <Spinner className='loader-style' /> :
                            <BsArrowLeftShort />
                        }
                    </div>
                </Col>
            </Row>
            <Formik
                // initialValues={{
                //     product_name: 'New product',
                //     // product_code: products?.length + 1,
                //     product_code: null,
                //     tags: selProductDtls?.tags ?? projectSettings?.product_tags ?? [],
                //     description: '',
                //     website: '',
                //     specifications: [],
                //     boundary_color: null,
                //     boundary_attributes: null,
                //     product_color: null,
                //     enc_id: null,
                //     position: null,
                //     ...selProductDtls
                // }}
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={(values, setFieldError) => {
                    addProduct(values, setFieldError)
                }}
                enableReinitialize
            >
                {({
                    errors,
                    values,
                    touched,
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    setFieldError
                }) => (
                    <>
                        {(selProductDtls?.position && !selProductDtls?.enc_id) &&
                            <>
                                {/* {!selProductDtls?.enc_id && setIsDirty(true)} */}
                                <AutosaveForm handleSubmit={handleAutoSave} setSavingTimer={setSavingTimer} savingTimer={savingTimer} />
                            </>
                        }
                        <form
                            id="productForm"
                            className="av-tooltip tooltip-label-bottom formGroups"
                            onSubmit={(e) => handleSubmit(e)}
                        >
                            {
                                addNew ?
                                    <div className='custom-scrollbar customScroll' style={{ height: mapDivSize }} >
                                        <div className='bar-sub'>
                                            {/* {(selProductDtls?.position?.x) ? ( */}
                                            <div>
                                                <div className='bar-sub-header' style={{ marginTop: 0 }} >
                                                    <p style={{ marginTop: 0 }} >Details</p>
                                                </div>
                                                <div className='pl-4 pr-4'>
                                                    <div className="marginBottom">
                                                        <Label for="exampleName" className="form-labels">Name</Label>
                                                        <Field
                                                            id="exampleName"
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Please Type"
                                                            name="product_name"
                                                            autoComplete="off"
                                                            value={values?.product_name}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                setSelProductDtls(prev => ({ ...prev, product_name: e.target.value }));
                                                                setIsDirty(true);
                                                            }}
                                                        />
                                                        {errors.product_name && touched.product_name ? (
                                                            <div className="text-danger mt-1">
                                                                {errors.product_name}
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    <div className="marginBottom">
                                                        <Label for="exampleName" className="form-labels">Product Code</Label>
                                                        <Field
                                                            id="exampleName"
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Please Type"
                                                            name="product_code"
                                                            autoComplete="off"
                                                            value={values?.product_code}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                setSelProductDtls(prev => ({ ...prev, product_code: e.target.value }))
                                                                setIsDirty(true);
                                                            }}
                                                        />
                                                        {errors.product_code && touched.product_code ? (
                                                            <div className="text-danger mt-1">
                                                                {errors.product_code}
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    <div className="marginBottom">
                                                        <Label for="exampleName" className="form-labels">Tags</Label>

                                                        <TagInputComp
                                                            tags={values.tags ?? []}
                                                            setTags={(values) => {
                                                                setFieldValue('tags', values)
                                                                setSelProductDtls(prev => ({ ...prev, tags: values }))
                                                                setIsDirty(true);
                                                            }}
                                                        />
                                                        {/* {errors.name && touched.name ? (
                                            <div className="text-danger mt-1">
                                                {errors.name}
                                            </div>
                                        ) : null} */}
                                                    </div>
                                                    <input hidden name='position' value={values?.position} />

                                                    <div className="marginBottom">
                                                        <Label for="exampleName" className="form-labels">Product Image</Label>

                                                        {/* <TickLabel label='Images' showTick={images?.length > 0} /> */}
                                                        <Row>
                                                            <Col md={4}>
                                                                {images?.length == 0 ?
                                                                    <div className='select-logo product-image' style={{ marginBottom: 0 }} onClick={() => imgInputRef.current.click()} >
                                                                        <p>+</p>
                                                                        <input key={fileKey} type='file' accept=".jpeg, .png, .jpg" ref={imgInputRef} hidden onChange={onSelectImg} />
                                                                    </div>

                                                                    :
                                                                    <>

                                                                        {/* {images?.map((img, index) => */}
                                                                        <div className='img-wrpr prduct' >
                                                                            <span className='image-container'>
                                                                                <img src={images[0]} style={{ borderRadius: '6px', border: '1px solid rgb(204, 204, 204)' }} />
                                                                                {/* <span
                                                                                            className="delete-icon"
                                                                                            onClick={() => handleDeleteImage(img, index, setFieldValue)}
                                                                                        >
                                                                                            <AiFillCloseCircle />
                                                                                        </span> */}
                                                                                <span className='delete-logo-icon' style={{ right: '0px' }}><div onClick={() => handleDeleteImage(images[0], 0, setFieldValue)} className='ml-4 p-1 rounded-circle' style={{ backgroundColor: '#E5E5E5', cursor: 'pointer', }} >
                                                                                    <IoMdClose style={{ fontSize: '10px' }} />
                                                                                </div></span>
                                                                            </span>
                                                                        </div>
                                                                        {/* )} */}
                                                                    </>
                                                                }
                                                            </Col>
                                                            <p className='mt-2 recomended-res-label'>Recommended Resolution:  240 × 240 px</p>
                                                        </Row>
                                                        <ImageUploader
                                                            onSubmit={(blob, url, blobUrl) => {
                                                                setCroppedImage(url);
                                                                setBlobImage(blob);

                                                            }}
                                                            onCancel={() => {
                                                                // setCroppedImage(null);
                                                                console.log("Cancelled");
                                                            }}
                                                            sourceImageUrl={previewImage}
                                                            setSourceImageUrl={setPreviewImage}
                                                            openCropModal={modal}
                                                            setOpenCropModal={setModal}
                                                            // toggle={toggle2}
                                                            name={`image_path`}
                                                            setFieldValue={setFieldValue}
                                                            setPostCall={setPostCall}
                                                            imgAspect={4 / 4}
                                                            diasbleFreeFlow={true}
                                                            from={'product'}

                                                        />
                                                        {/* <Label for="exampleName" className="form-labels">Images</Label> */}
                                                    </div>
                                                    <div className="marginBottom">
                                                        <Label for="exampleName" className="form-labels">Description</Label>
                                                        <textarea
                                                            id="exampleName"
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Please Type"
                                                            name="description"
                                                            rows={4}
                                                            autoComplete="off"
                                                            value={values?.description}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                setSelProductDtls(prev => ({ ...prev, description: e.target.value }));
                                                                setIsDirty(true);
                                                            }}

                                                        ></textarea>
                                                        {errors.description && touched.description ? (
                                                            <div className="text-danger mt-1">
                                                                {errors.description}
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    <div className="marginBottom">
                                                        <Label for="exampleName" className="form-labels">Website</Label>
                                                        <Field
                                                            id="exampleName"
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Please Type (Eg. www.demo.com)"
                                                            name="website"
                                                            autoComplete="off"
                                                            value={values?.website}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                setSelProductDtls(prev => ({ ...prev, website: e.target.value }));
                                                                setIsDirty(true);

                                                            }}
                                                        />
                                                        {errors.website && touched.website ? (
                                                            <div className="text-danger mt-1">
                                                                {errors.website}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className='bar-sub-header' >
                                                    <p style={{ marginTop: 0 }} >Specifications</p>
                                                    <div className='plus-icon' onClick={() => setSpecifications(prev => [...prev, {}])}>
                                                        <GoPlus />
                                                    </div>
                                                </div>
                                                <div className='pl-4 pr-4'>

                                                    {specifications.map((sp, id) => <ProdSpecItem spec={sp} index={id} setSpecifications={setSpecifications} specifications={specifications} name='specifications' setFieldValue={setFieldValue} setIsDirty={setIsDirty} />)}

                                                </div>

                                                <div className='bar-sub-header' >
                                                    <p style={{ marginTop: 0 }} >Style</p>
                                                </div>
                                                <div className='pl-4 pr-4'>

                                                    <ColorPicker
                                                        label={'Active Destination Pin Colour'}
                                                        value={values.product_color ?? projectSettings?.product_color ?? '#320101'}
                                                        name={'product_color'}
                                                        onChange={(e) => {
                                                            // handleChange(e);
                                                            setColor(e)
                                                            // setFieldValue('fake_value', e.target.value)
                                                            // setTimeout(() => {
                                                            //     setSelLocationDtls(prev => ({ ...prev, ...values, location_color: e }))
                                                            // }, 1000);
                                                        }}
                                                        handleOkClick={(e) => {
                                                            setSelProductDtls(prev => ({ ...prev, ...values, product_color: e }))
                                                        }}
                                                        setFieldValue={setFieldValue}
                                                        isOpen={openPicker === 'product_color'}
                                                        setOpenPicker={setOpenPicker}
                                                        onClick={() => handlePickerClick('product_color')}
                                                        color={color} setColor={setColor} setIsDirty={setIsDirty}

                                                    />
                                                </div>
                                                <div className='btn-wrpr' >
                                                    <Button
                                                        className="btnCancel "
                                                        type="button"
                                                        size="medium"
                                                        hidden
                                                        onClick={() => { setAddNew(false) }}
                                                    >
                                                        Cancel
                                                    </Button>

                                                    {/* <Button
                                                        className="btn-primary bar-btn"
                                                        htmlType="submit"
                                                        type="primary"
                                                        size="medium"
                                                        id='productSubmitBtn'
                                                    // hidden
                                                    >
                                                        Submit
                                                    </Button> */}

                                                </div>
                                            </div>
                                            {/* ) : ( */}
                                            {/* <div className='click-map-alert'>
                                                    <div className='warning-pin-div'>
                                                        <div className="d-flex align-items-center justify-content-center mb-2">
                                                            <div className="info-cont">
                                                                <FaInfo />
                                                            </div>
                                                        </div>
                                                        <div className=" text-center  ">
                                                            <p className='label color-labels' >Click on the map to place your product pin. Once you have placed the pin, you will be able to edit the pin details.</p>

                                                        </div>
                                                    </div>
                                                </div> */}
                                            {/* )} */}
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className='bar-sub-header' style={{ marginRight: '14px' }}>
                                            <p style={{ marginTop: 0 }} >Add New Product Pin</p>
                                            <div className='plus-icon' onClick={() => addProductClick()}>
                                                <GoPlus />
                                            </div>
                                            {/* <AiFillPlusCircle size={19} style={{ cursor: 'pointer' }} color='#26A3DB'  /> */}
                                        </div>
                                        <div className='mb-2 text-right' style={{ marginRight: '14px' }}>
                                            <Button
                                                className="btn-primary bar-btn"
                                                type="button"
                                                size="medium"
                                                onClick={bulkUploadClick}
                                            >
                                                Bulk Product Upload
                                            </Button>
                                        </div>
                                        <div className="d-flex bar-search mb-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={(e) => handleSearch(e)}
                                                onKeyDown={(e) => handleBlockEnter(e)}
                                            />
                                            <div
                                                className="input-group-append"
                                                style={{ marginLeft: "-36px" }}
                                            >
                                                <span
                                                    className="input-group-text"
                                                    style={{
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                        padding: '4px'
                                                    }}
                                                >
                                                    <FiSearch className="iconStyle" />
                                                </span>
                                            </div>
                                        </div>
                                        <div className='custom-scrollbar customScroll' style={{ height: mapDivSize - 140 }} >
                                            {filteredData.filter(p => p?.floorId === selFloorPlanDtls?.id)?.map((plan, idx) => renderProductItem(plan, idx))}
                                        </div>
                                    </>}

                            <Button
                                className="btn-primary bar-btn"
                                htmlType="submit"
                                type="primary"
                                size="medium"
                                id='productSubmitBtn'
                            // hidden
                            >
                                Submit
                            </Button>
                            {/* <Label for="exampleEmail1" className="form-labels">Name</Label> */}

                        </form>
                    </>
                )}
            </Formik>
            <ProPinModal
                isOpen={modalPlan}
                toggle={toggle3}
                totalPinsUsed={totalPinsUsed}
                planDetails={planDetails}
                addCardDetails={addCardDetails}
                projectSettings={projectSettings}
            />
            <PaymentForm
                toggleStripe={toggleStripe}
                stripeModal={stripeModal}
                planDetails={planDetails}
                project_id={id}
                onSideBarIconClick={onSideBarIconClick}
                activeTab={activeTab}

            />
            <BulkUploadPin
                modal={modalBulk}
                setModal={setModalBulk}
                type={'product'}
                projectSettings={projectSettings}
                selFloorPlanDtls={selFloorPlanDtls}
                getList={() => getProductList(selFloorPlanDtls?.enc_id)}
                handleEnableDisable={handleEnableDisable}
            />
        </div>
    )
}

export default ProductSideBar

