export const months = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
};

export const rangeOption = [
    {
        value: '5',
        label: 'Last Week'
    },
    {
        value: '4',
        label: 'Last Month'
    },
    {
        value: '3',
        label: 'Last 3 Months'
    }, {
        value: '2',
        label: 'Last 6 Months'
    },
    {
        value: '1',
        label: 'Last 12 Months'
    }
]