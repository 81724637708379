import { removeFabricObjectsByName } from "../bringFabricObjects";
import { fabric } from "fabric";


const canvasBGimage = (
    canvas,
    projectSettings,
    addNewFloor,
    selFloorPlanDtls,
    canvasContainerRef,
    activeTab,
    imgSrc,
    zoom,
    bgColor,
    scaleX,
    scaleY,
    zoomInOut
) => {
    if (imgSrc && canvas.current && addNewFloor) {
        const isSVG = imgSrc.toLowerCase().endsWith('.svg');
        if (isSVG) {
            fabric.loadSVGFromURL(imgSrc, function(objects, options) {
                var obj = fabric.util.groupSVGElements(objects, options);
                obj.set({
                    left: 0,
                    top: 0,
                    scaleX: canvas.current.width / obj.width,
                    scaleY: canvas.current.height / obj.height
                });
                canvas.current.add(obj);
                canvas.current.renderAll();
            });
        } else {
            fabric.Image.fromURL(imgSrc, (img) => {
                console.log(imgSrc, 'imgSrc')
                let canvasAspectRatio =
                    selFloorPlanDtls?.width / selFloorPlanDtls?.height;
                let imgAspectRatio = img.width / img.height;
                let scaleFactor = zoom ?? zoomInOut;

                if (canvasAspectRatio > imgAspectRatio) {
                    scaleFactor = selFloorPlanDtls?.width / img.width;
                } else {
                    scaleFactor = selFloorPlanDtls?.height / img.height;
                }

                const originalWidth = selFloorPlanDtls?.width;
                const originalHeight = selFloorPlanDtls?.height;

                // const viewportWidth = selFloorPlanDtls?.width ?? canvasContainerRef.current.clientWidth;
                // const viewportHeight = selFloorPlanDtls?.height ?? canvasContainerRef.current.clientHeight;
                // const canvasAspectRatio = viewportWidth / viewportHeight;
                // const imgAspectRatio = img.width / img.height;

                // let scaleFactor = zoom || zoomInOut;

                // if (canvasAspectRatio > imgAspectRatio) {
                //   scaleFactor = viewportWidth / img.width;
                // } else {
                //   scaleFactor = viewportHeight / img.height;
                // }

                const newScaleX = img.scaleX * (scaleX ?? zoomInOut);
                const newScaleY = img.scaleY * (scaleY ?? zoomInOut);

                img.scale(scaleFactor).set({
                    originX: "center",
                    originY: "center",
                    scaleX: zoom ?? newScaleX ?? zoomInOut,
                    scaleY: zoom ?? newScaleY ?? zoomInOut,
                    left: selFloorPlanDtls?.width / 2,
                    top: selFloorPlanDtls?.height / 2
                });

                // Set the image as canvas background
                canvas.current.setBackgroundImage(
                    img,
                    canvas.current.renderAll.bind(canvas.current),
                    {
                        backgroundImageStretch: false,
                    }
                );
                // canvas.current.centerObject(img);
                removeFabricObjectsByName(canvas, "backgroundRect");
                const backgroundRect = new fabric.Rect({
                    height: canvasContainerRef.current.clientHeight,
                    width: canvasContainerRef.current.clientWidth,
                    left: 0,
                    top: 0,
                    // fill: filterColor,
                    fill: 'transparent',
                    selectable: false,
                    name: "backgroundRect",
                    hoverCursor: activeTab == "floorDetails" ? "default" : "pointer"
                });
                canvas.current.add(backgroundRect);
                canvas.current.sendToBack(backgroundRect);
            });
        }
    }
    else {
        canvas.current.setBackgroundImage(
            null,
            canvas.current.renderAll.bind(canvas.current)
        );
        removeFabricObjectsByName(canvas, "backgroundRect");
        const backgroundRect = new fabric.Rect({
            height: canvasContainerRef.current.clientHeight,
            width: canvasContainerRef.current.clientWidth,
            left: 0,
            top: 0,
            // fill: filterColor,
            fill: 'transparent',
            selectable: false,
            name: "backgroundRect",
            hoverCursor: activeTab == "floorDetails" ? "default" : "pointer"
        });
        canvas.current.add(backgroundRect);
        canvas.current.sendToBack(backgroundRect);
        canvas.current.renderAll()
    }
};

export default canvasBGimage;