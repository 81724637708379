import { bringFabricObjectsToFrontByName } from "./bringFabricObjects";

const tracingLengthZoomLevel = (canvas, zoom, mouse) => {
    canvas.current.forEachObject(function (obj) {
        if (obj.name === 'length_text_temp' || obj.name === 'length_text' || obj.name === 'tracing_obj_length') {
            const baseFontSize = 12; // Adjust this as per your base font size
            const adjustedFontSize = baseFontSize * (1 / zoom);
            obj.set('fontSize', adjustedFontSize);
            if (obj.stroke && obj.strokeWidth) {
                const baseStrokeWidth = 3;
                const adjustedStrokeWidth = baseStrokeWidth * (1 / zoom);
                // const adjustedStrokeWidth = zoom >= 1 ? baseStrokeWidth * (1 / zoom) : baseStrokeWidth * (1 * zoom);
                obj.set('strokeWidth', adjustedStrokeWidth);
            }
        } else if (obj.name === 'corner_point') {
            const baseRadius = 5;
            const adjustedRadius = zoom >= 1 ? baseRadius : baseRadius * (1 / zoom);
            obj.set('radius', adjustedRadius);
        }
        else if (obj.name === 'temp' && obj?.type === 'polyline') {
            if (obj.stroke && obj.strokeWidth) {
                const baseStrokeWidth = 3;
                const adjustedStrokeWidth = zoom <= 1 ? baseStrokeWidth * (1 / zoom) : 1;
                obj.set('strokeWidth', adjustedStrokeWidth);
            }
        }
    });
    // canvas.current.renderAll();
};

const navigationPathZoomLevel = (canvas, zoom, projectSettings) => {
    canvas.current.forEachObject(function (obj) {
        if (obj.name === 'node' && !obj.id.includes('_')) {
            const baseRadius = 7;
            const adjustedRadius = zoom >= 1 ? baseRadius : baseRadius * (1 / zoom);
            obj.set('radius', adjustedRadius);
        } else if (obj.name === 'line_starter_poly' || obj.name === 'path') {
            if (obj.stroke && obj.strokeWidth) {
                const baseStrokeWidth = 1;
                const adjustedStrokeWidth = zoom <= 1 ? baseStrokeWidth * (1 / zoom) : baseStrokeWidth;
                obj.set('strokeWidth', adjustedStrokeWidth);

            }
        } else if (obj.name === 'short_path') {
            if (obj.stroke && obj.strokeWidth) {
                const baseStrokeWidth = projectSettings?.navigation_thick ?? 3;
                const adjustedStrokeWidth = zoom <= 1 ? baseStrokeWidth * (1 / zoom) : baseStrokeWidth;
                obj.set('strokeWidth', adjustedStrokeWidth);
                // canvas.current.bringToFront(obj)
            }
        }
    })
}



export { tracingLengthZoomLevel, navigationPathZoomLevel };
