export const DownloadForExcel = (dataRes, fileName) => {
    const blob = new Blob([dataRes], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // set the filename with .xlsx extension
    document.body.appendChild(link); // append the link to the DOM
    link.click();
    document.body.removeChild(link); // remove the link from the DOM after download
    window.URL.revokeObjectURL(url);
}

export const DownloadForPdf = (dataRes, pdfName) => {
    const blob = new Blob([dataRes], {
        type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${pdfName}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export const dateFormatDDMMYYYY = (inputDateString) => {
    if (inputDateString) {


        const inputDate = new Date(inputDateString);
        // Get day, month, and year
        const day = String(inputDate.getDate()).padStart(2, '0');
        const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = inputDate.getFullYear();
        // Formatted date string
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    } else {
        return ''
    }
}

export const dateFormatYYYYMMDD = (inputDateString) => {
    if (inputDateString) {
        const inputDate = new Date(inputDateString);
        // Get day, month, and year
        const day = String(inputDate.getDate()).padStart(2, '0');
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const year = inputDate.getFullYear();
        // Formatted date string
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    } else {
        return ''
    }

}